@tailwind base;
@tailwind components;
@tailwind utilities;

#chat-widget-container {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  #chat-widget-container {
    width: 100%;
    height: 100%;
  }
}
